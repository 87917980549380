<template>
  <div v-if="0 !== tags.length" class="post-tags">
    <TagLink class="post-tags__item" :tag="tags[0]">
      {{ tags[0].name }}
    </TagLink>
    <div v-if="1 !== tags.length" class="post-tags__dropdown-container">
      <span class="post-tags__item" @click="onShowList">
        +{{ hiddenTags.length }}
      </span>
      <div
        v-if="isShownList"
        v-on-click-outside="onCloseList"
        class="post-tags__dropdown"
      >
        <TagLink v-for="tag in hiddenTags" class="post-tags__item" :tag="tag">
          {{ tag.name }}
        </TagLink>
      </div>
    </div>
  </div>
</template>

<script setup>
import { vOnClickOutside } from "@vueuse/components";
import TagLink from "~/nuxt_modules/blog/ui/components/TagLink.vue";

const props = defineProps({
  tags: { type: Array, required: true },
});

const isShownList = ref(false);

const hiddenTags = computed(() => {
  return props.tags.filter((_, index) => 0 !== index);
});

const onShowList = () => {
  isShownList.value = true;
};

const onCloseList = () => {
  isShownList.value = false;
};
</script>

<style scoped lang="scss">
.post-tags {
  @include flex-container(row, flex-start, center);
  gap: 4px;
  flex-wrap: wrap;

  &__item {
    @include font(10, 14);
    text-align: center;
    color: var(--color-blue-dark);

    background-color: var(--color-blue-lightest);
    border-radius: 30px;

    padding: 4px 16px;

    cursor: pointer;
  }

  &__dropdown-container {
    position: relative;
    display: flex;
  }

  &__dropdown {
    position: absolute;
    left: 0;
    top: 32px;
    z-index: 1000;

    min-width: 160px;

    @include flex-container(column, flex-start);
    gap: 8px;

    background-color: white;
    border-radius: 12px;
    box-shadow: 0 1px 32px 0 #00000026;

    padding: 8px;
  }
}
</style>

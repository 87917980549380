<template>
  <div class="post-date">
    <slot />
    <time :datetime="new Date(postDate).toISOString()" class="post-date__date">
      {{ getDate(postDate) }}
    </time>
  </div>
</template>

<script setup>
const props = defineProps({
  postDate: { type: String, required: true },
});
const getDate = (date) => {
  return useState("post date" + date, () =>
    new Intl.DateTimeFormat("uk-UA", {}).format(Date.parse(date)),
  );
};
</script>

<style scoped lang="scss">
.post-date {
  @include flex-container(row, flex-start, center);
  gap: 4px;

  &__date {
    @include font(14, 20);
    color: var(--color-sky-darkest);
  }
}
</style>
